import React from 'react'
import { Router, RouteComponentProps, Link, navigate } from '@reach/router'

import CC from './Pages/CC'
import Portfolio from './Pages/Portfolio'
import Home from './Pages/Home'
import About from './Pages/About'

import MobileView from './Pages/MobileView'

console.log("Created by Los Studios.\nwww.carlossantos.xyz")

const App = (props: RouteComponentProps) => {
    if(window.innerWidth < 750){
        return(
            <MobileView />
        )
    }
    return(
        <Router id='router'>
            <Home path="/"/>
            <Portfolio path="/portfolio/:id"/>
            <CC path="/cc" />
            <About path="/about" />
            <NotFound default />
        </Router>
    )
}

export const NotFound = (RouteComponentProps) => {
    // navigate('/')
    return(<Home/>)
}

export default App
