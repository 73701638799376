import React from 'react'
import Menu from '../Components/Menu'
import { RouteComponentProps } from '@reach/router'

const Home = (props: RouteComponentProps) =>{
    return(
        <div className='Home'>
            <Menu />
        </div>
    )
}

export default Home