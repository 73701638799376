import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'

const Menu = (props: RouteComponentProps) => {
    return(
        <div className='Menu'>
            <div id='text'>
            nicole chan
            <br/>
            
            <div id='left'>
            <br/>       
                Strands
                <br/>
                <div id="parts">
                    <Link to="/portfolio/part_i"><span id="">Part I</span></Link>
                    <Link to="/portfolio/part_ii"><span id="">Part II</span></Link>
                    <Link to="/portfolio/part_iii"><span id="">Part III</span></Link>
                </div>
                <br/>
                <Link to="/portfolio/things_in_between">Things Inbetween</Link>
            </div>
            <div id="right">
                <Link to="/cc">
                    Collaborations and
                    <br/>
                    Commissions
                </Link>
                <br/>
            <br/>
                <Link to="/about">About</Link>
            </div>
            </div>
        </div>
    )
}

export default Menu