import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'

const About = (props: RouteComponentProps) =>{
    return (
        <div className='About'>
            <div id='gohome'>
                <Link to='/'>
                    home
                </Link>
            </div>
            <div className='Menu'>
<div id='text'>
<div id='left'>
Nicole Hwa-Young Chan is a photographer who fears the dissolving nature of memories.
In her work, she attempts to create a sense of permanence and a space to study moments passed.  
<br/>
<br/>
<span id="u">Education</span>
<br/><br/>
New York University - BFA in Fashion Theory and History
<br/>
<br/>
<span id="u">Press</span>
<br/><br/>

ZIP Group Art Show | 3 Photographs (2018)<br/>
Peddler Journal: Grandmother Issue | “Halmoni’s Patjuk” (2018)<br/>
Peddler Journal: Dumpling Issue | “Shu Jiao Fu Zhou” (2019)<br/>
Peddler Journal: Immigration Issue | “1,000 Each” (2020)<br/>
STP | Yard Sale | Group Show benefiting the Loveland Foundation (2020)<br/>
Melted Magazine | “An Interview with Sedona” (2020)<br/>
Flaunt Magazine | “Sedona Closer” (2020)<br/>
</div> 
<div id='right'>
<span id="u">Contact</span>
<br/>
nycolehchan-at-gmail
<br/>
<a href="https://instagram.com/nycolechan">@nycolechan</a>
<br/>
</div></div>
            </div>
        </div>
    )
}

export default About