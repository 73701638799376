import React, { useState,useRef } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactPlayer from 'react-player'

import { 
    photoSeries,
    getPhotos,
    getDesc
} from '../Pages/utils'

export interface TCCImg{
    coverImg: string,
    hoverText: string,
    gallery: Array<{ original: string }>
}

const _images = getPhotos(photoSeries.COLLAB)

const videoComponent = (url:string) => {
    return (
        <div className="reactPlayer">
            <ReactPlayer width={"800px"} height={"800px"} url={url} controls={true}/>
        </div>
    )
}

const simages: Array<TCCImg> = Object.entries(_images).map((entry:Array<any>)=>{
    let key = entry[0]
    let values = entry[1]

    let coverImg =  values.filter( (url:string) => url.toLowerCase().includes("1_cover") )[0] || ""
    let hoverText = getDesc(key) || ""
    return {
        coverImg,
        hoverText,
        gallery: values.map(( original:string )=> {
            if(original.includes("vimeo") || original.includes("youtube")){
                return {
                    original,
                    embedUrl: original,
                    renderItem:()=>{ return videoComponent(original) }
                }
            }
            return {original} 
        } )
    }
})

const getImgPosition = (idx: number) =>{
    if(idx % 3 === 0) return `IMG_left`
    if(idx % 3 === 1) return `IMG_center`
    if(idx % 3 === 2) return `IMG_right`
}

const CC = (props: RouteComponentProps) =>{
    const [IG,updateImage] = useState([])
    const [showGallery,updateGallery] = useState(true)
    const ig_ref = useRef(null)
    const revealGallery  = (images: Array<{original:string}>) =>{
        updateImage(images)
        updateGallery(true)
        ig_ref.current.fullScreen()
    }
    const hideGallery = () => {
        updateImage([])
        // updateGallery(false)
        ig_ref.current.exitFullScreen()
    }

    return (
        <div className='CC'>
            <div className="MenuBoxCC" >
                    <div id='nicolehome'>
                    <Link to="/">
                        nicole chan
                    </Link>
                    <br/>
                    <br/>
                    <div id='left'>       
                Strands
                <br/>
                <div id="parts">
                    <Link to="/portfolio/part_i"><span id="">Part I</span></Link>
                    <Link to="/portfolio/part_ii"><span id="">Part II</span></Link>
                    <Link to="/portfolio/part_iii"><span id="">Part III</span></Link>
                </div>
                <br/>
                <Link to="/portfolio/Things_Inbetween">Things Inbetween</Link>
            </div>
                    <br/>
                    <br/>
                    <Link to="/about">
                        about
                    </Link>
                    </div>
            </div>
            <div className='cc_images'>
            {
                simages.map( (img,idx)=>{
                    return(
                       <HoverImage key={idx} idx={idx} desc={img.hoverText} revealGallery={()=>{revealGallery(img.gallery)}} imgUrl={img.coverImg} />
                    )
                })
            }
            </div>
            {
                showGallery && (
                <ImageGallery 
                    additionalClass="IG gallery"
                    ref={ig_ref}
                    items={IG}
                    showThumbnails={false}
                    showPlayButton={false}
                    onScreenChange={(state)=>{
                        if(!state) hideGallery()
                    }}
                    renderRightNav={forwardButton}
                    renderLeftNav={backButton}
                    renderFullscreenButton={fsButton}
                />
            )
            }   
        </div>
    )
}

const forwardButton = (click,disabled)=>{
    return(
        <div className={'forwardButtonNav '+'image-gallery-custom-left-nav'} onClick={click}>next</div>  
    )
}
const backButton = (click,disabled)=>{
    return(
        <div className={'backButtonNav '+'image-gallery-custom-right-nav'} onClick={click} >prev</div>  
    )
}
const fsButton = (click,isfs)=>{
    return(
        <div
            className={`fullscreenclose `+ `image-gallery-fullscreen-button${isfs ? ' active' : ''}`} 
            onClick={click}>close fullscreen</div>  
    )
}


export interface HoverImageProps{
    idx: number 
    revealGallery():void 
    imgUrl: string 
    desc: string
}

const HoverImage = (props: HoverImageProps) => {
    const [desc, showDesc] = useState(false)
    
    return(
        <div
            className={ `image ${props.imgUrl==="" ? "hide" : ""}` }
            id={getImgPosition(props.idx)} 
            key={props.idx}
        >
        <div className="HoverImageWrapper"
            onClick={props.revealGallery}    
        >
                <img 
                    className={`ccimg ${desc ? "blur" : ""}`}
                    src={props.imgUrl} 
                    alt={`image_${props.idx}`}
                    onMouseEnter={()=>{ showDesc(true) }}
                    onMouseLeave={()=>{ showDesc(false) }}    
                />
                {
                    desc && (
                        <div className='pictureDescription'
                        onMouseEnter={()=>{ showDesc(true) }}
                        onMouseLeave={()=>{ showDesc(false) }}
                        >
                            <SimpleBar className="sbDesc" style={{ maxHeight: 150 }}>
                                <div className="picDescText" >
                                    {props.desc}
                                </div>    
                            </SimpleBar>
                            
                        </div>
                    )
                }
            </div>
    </div>   
    )
}

export default CC

