import { default as photos } from './photos.json'

export enum photoSeries{
    COLLAB,
    STRANDS,
    TIB
}


export const getPhotos = (series: photoSeries) => {
    switch(series){
        case photoSeries.COLLAB:
            return photos.collaborations
        case photoSeries.STRANDS:
            return photos.strands
        case photoSeries.TIB:
            return photos.things_in_between
    }
}

export const getDesc = (key: string) => {
    switch(key){
        case "coap":
            return "Come Out and Play | Lorelei Goude | Nick Shaya\nStylist\n"
        case "p_dumpling":
            return "Peddler Journal |  Dumpling Issue:\nShu Jiao Fu Zhou\nContributor"
        case "p_grandmother":
            return "Peddler Journal | Grandmother Issue:\nHalmoni Patjuk\nContributor\n"
        case "p_i":
            return "Peddler Journal | Immigrant Issue:\nA Two Month Meal\nContributor"
        case "sedona":
            return "Sedona\nPhotographer"
        case "sip":
            return "Sketching in Pen | José Benjamín | Ben Elias\nProducer"
        case "tmo":
            return "Thingamajig Objects by Kristen Chiu\nPhotographer"
        default:
            return ""
    }
}