import React, { useState, useEffect, useRef } from 'react'
import "animate.css"
const images = [
    "https://losstudiosdev.nyc3.cdn.digitaloceanspaces.com/Frame%205.jpg",
    "https://losstudiosdev.nyc3.cdn.digitaloceanspaces.com/Frame%204.jpg",
    "https://losstudiosdev.nyc3.cdn.digitaloceanspaces.com/cover1.JPG"
]

const copyToClipboard = ()=>{
    var copyText = document.createElement("textarea");
    copyText.innerText = ""
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
  
    /* Copy the text inside the text field */
    document.execCommand("copy");
}


const MobileView = () =>{
    const [visible,updateVisible] = useState('')
    useEffect(()=>{
        setTimeout(()=>{
            updateVisible('ontop')
        },8000)
    },[])
    // todo: dectect landscape vs portrait and restrict landscape
    return(
        <div className='MobileView'>
            <div 
                id='infocard'
                className={`animate__animated animate__fadeIn ${visible}`}
            >
                <div id='name'>nicole chan</div>
                <div id='bio'>
                    Nicole Hwa-Young Chan is a photographer who fears the dissolving nature of memories.
                    In her work, she attempts to create a sense of permanence and a space to study moments passed.  
                </div>
                
                <div id='social'>
                    <a className="fa fa-instagram fa-lg" aria-hidden="true"
                        href="https://instagram.com/nycolechan"
                    ></a>
                    <div id='social_dash'
                    >
                        email: nycolehchan-at-gmail
                    </div>
                </div>
                
                <br/>
            </div>
            <img className="slideImg animate__animated animate__fadeOut animate__delay-5s" id="one" src={images[0]}/>
            <img className="slideImg animate__animated animate__fadeOut animate__delay-4s" id="two" src={images[1]}/>
            <img className="slideImg animate__animated animate__fadeOut animate__delay-3s" id="three" src={images[2]}/>
            <div id='startup' className="animate__animated animate__fadeOut animate__delay-2s">
                <div id='startuptitle' className="animate__animated animate__fadeIn animate__delay-1s ">
                    nicole chan
                </div>
            </div>
            
        </div>
    )
}

export default MobileView