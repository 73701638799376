import React, { useState, useRef } from 'react'
import { RouteComponentProps,Link } from '@reach/router'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'

import { 
    photoSeries,
    getPhotos,
    getDesc
} from '../Pages/utils'

const get_index = (path: string) => {
    switch (path) {
        case "part_i":
            return 1
        case "part_ii":
            return 2
        case "part_iii":
            return 3
        default:
            return -1000
    }
}

interface PortfolioProps extends RouteComponentProps{
    id?: string
}

const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2
  };

const Portfolio = (props: PortfolioProps) =>{
    let _images 
    let images
    if(props.id.includes("part")){
        _images = getPhotos(photoSeries.STRANDS)
     images = _images[props.id].map((original: string)=>{
        return { original }
    })
    }else{
        _images = getPhotos(photoSeries.TIB)
        images = _images.map( (original: string) => { return { original } } )
    }

    const [currentSlide,updateSlide] = useState(0)
    const [navVisible,updateNavVisibility] = useState(false)
    const refContainer = useRef(null);
    const next = () => {
        if(currentSlide <( images.length - 1)){
            refContainer.current.slideToIndex(refContainer.current.getCurrentIndex()+1)
            updateSlide(refContainer.current.getCurrentIndex()+1)
        }
    }

    const prev = () => {
        if( currentSlide>0 ){
            refContainer.current.slideToIndex(refContainer.current.getCurrentIndex()-1)
            updateSlide(refContainer.current.getCurrentIndex()-1)
        }
    }

    document.addEventListener("keydown",(e)=>{
        if(e.code==="Escape"){
            updateNavVisibility(false)
            updateNavVisibility(false)
        }
    })

    const fullScreen=()=>{
        refContainer.current.fullScreen()
        updateNavVisibility(true)
    }
    const handleScreenChange=(e)=>{
        if(!e){
            updateNavVisibility(false)
        }
    }

    const pidx = get_index(props.id)
    var settings = {
        dots: true,
      };
      
    return(
        <div className='Portfolio'>
            <div id='gohome'>
                <Link to='/'>
                    home
                </Link>
            </div>
        <div className="MenuP">
            <div className='backButton' onClick={prev} >prev</div>
            <ImageGallery 
             items={images}
             showThumbnails={false}
             showPlayButton={false}
             additionalClass="gallery"
             ref={refContainer}
             showNav={navVisible}
             showFullscreenButton={navVisible}
             onScreenChange={handleScreenChange}
             renderRightNav={forwardButton}
             renderLeftNav={backButton}
             renderFullscreenButton={fsButton}
             />
            <div className='forwardButton' onClick={next}>next</div>
            <div id='fullscreen' onClick={fullScreen}>fullscreen</div> 
        </div>
        
        </div>
    )
}

const forwardButton = (click,disabled)=>{
    return(
        <div className={'forwardButtonNav '+'image-gallery-custom-left-nav'} onClick={click}>next</div>  
    )
}
const backButton = (click,disabled)=>{
    return(
        <div className={'backButtonNav '+'image-gallery-custom-right-nav'} onClick={click} >prev</div>  
    )
}
const fsButton = (click,isfs)=>{
    return(
        <div
            className={`fullscreenclose `+ `image-gallery-fullscreen-button${isfs ? ' active' : ''}`} 
            onClick={click}>close fullscreen</div>  
    )
}



export default Portfolio

